<template>
  <Table
    ref="resourcesTable"
    :load-items="loadItems"
    :columns="columns"
    title-key="resources.title"
    :cell-props="{ class: 'text-truncate' }"
    data-cy="resourcesTable"
  >
    <template #actions="{ reload }">
      <v-row>
        <v-col cols="12" xl="2" lg="2" md="3" sm="4">
          <ActiveSelect
            v-model="filter.active"
            data-cy="filterActive"
            :label="t('resources.active')"
            hide-details
            clearable
          />
        </v-col>
        <v-col cols="12" xl="2" lg="3" md="4" sm="4">
          <TextField
            v-model="filter.name"
            data-cy="filterName"
            label-key="resources.name"
            icon="i-mdi:magnify"
            hide-details
            clearable
          />
        </v-col>
        <v-col cols="12" xl="2" lg="3" md="4" sm="4" class="me-auto">
          <Select
            v-model="filter.type"
            :items="types"
            :label="t('resources.type')"
            data-cy="filterType"
            hide-details
            clearable
          />
        </v-col>
        <template v-if="hasCreatePermission">
          <v-col cols="12" sm="auto">
            <Btn
              :block="isMobile"
              data-cy="resourcesAdd"
              prepend-icon="i-mdi:plus"
              @click="() => openDialog(newResourceId)"
            >
              {{ t('common.add') }}
            </Btn>
            <LazyResourceFormDialog
              :get-dialog-ref="getDialogRef(newResourceId)"
              @submit="reload"
            />
          </v-col>
        </template>
      </v-row>
    </template>
    <template
      v-if="hasUpdatePermission || hasDeletePermission"
      #item-actions="{ item, reload }"
    >
      <Btn
        v-if="hasUpdatePermission"
        :data-cy="`resourceEdit-${item.name}`"
        icon="i-mdi:pencil-outline"
        variant="text"
        color="primary"
        tile
        density="compact"
        @click="() => openDialog(`edit-${item.id}`)"
      />
      <LazyResourceFormDialog
        :get-dialog-ref="getDialogRef(`edit-${item.id}`)"
        :initial-value="item"
        @submit="reload"
      />
      <template v-if="hasDeletePermission">
        <v-tooltip
          v-if="!item.isDeletable"
          :text="t('resources.deleteResourceNotAvailable')"
        >
          <template #activator="{ props }">
            <span v-bind="props">
              <Btn
                :data-cy="`resourceDelete-${item.name}`"
                icon="i-mdi:delete-outline"
                variant="text"
                color="error"
                tile
                density="compact"
                disabled
                @click="() => openDialog(`delete-${item.id}`)"
              />
            </span>
          </template>
        </v-tooltip>
        <Btn
          v-else
          :data-cy="`resourceDelete-${item.name}`"
          icon="i-mdi:delete-outline"
          variant="text"
          color="error"
          tile
          density="compact"
          @click="() => openDialog(`delete-${item.id}`)"
        />
        <LazyConfirmDialog
          :get-dialog-ref="getDialogRef(`delete-${item.id}`)"
          title-key="resources.deleteResource"
          text-key="resources.deleteResourceText"
          data-cy="resourceDeleteDialog"
          :submit="() => deleteItem(item)"
        />
      </template>
    </template>
  </Table>
</template>

<script setup lang="ts">
import { apiKeys } from '@/constants/apiKeys';
import { ResponseStatus } from '@/constants/responseStatus';
import { debounce, isEqual } from 'lodash';
import { type ResourceDto, ResourcesService, ResourceType } from '@/api';
import TableCellChipList from '@/components/table/cell/TableCellChipList.vue';
import type { TableLoadItemsOptions } from '@/types/table';
import { UserPermission } from '@/constants/userPermission';
import type Dialog from '@/components/Dialog.vue';
import type { VNodeRef } from 'vue';
import { SnackbarType } from '@/constants/snackbarType';
import TableCellActive from '@/components/table/cell/TableCellActive.vue';

const { apiRequest } = useApi();
const { t } = useI18n();
const { isMobile } = useCssBreakpoints();
const snackbar = useSnackbar();
useSeoMeta({ title: () => t('layout.default.resources') });

const { hasPermission } = usePermissionUtils();
const hasCreatePermission = computed(() =>
  hasPermission(UserPermission.ResourcesCreate),
);
const hasUpdatePermission = computed(() =>
  hasPermission(UserPermission.ResourcesUpdate),
);
const hasDeletePermission = computed(() =>
  hasPermission(UserPermission.ResourcesDelete),
);

const columns = [
  {
    key: 'active',
    titleKey: 'resources.active',
    headerProps: {
      'data-cy': 'resourceTableHeaderActive',
    },
    component: TableCellActive,
  },
  {
    key: 'name',
    titleKey: 'resources.name',
    headerProps: {
      'data-cy': 'resourceTableHeaderName',
    },
    width: '20%',
  },
  {
    key: 'note',
    titleKey: 'resources.note',
    sortable: false,
    width: '30%',
  },
  {
    key: 'type',
    titleKey: 'resources.type',
    headerProps: {
      'data-cy': 'resourceTableHeaderType',
    },
    mapper: (item: ResourceDto) => t(`resources.types.${item.type}`),
    sortable: false,
    width: '10%',
  },
  {
    key: 'elementaryActivities',
    titleKey: 'resources.elementaryActivities',
    component: TableCellChipList,
    sortable: false,
  },
  {
    key: 'actions',
    titleKey: '',
  },
];

const types = Object.values(ResourceType).map((value) => ({
  value: t(`resources.types.${value}`),
  id: value,
}));

const filter = reactive<{
  active: boolean | null;
  name: string | null;
  type: ResourceType | null;
}>({
  active: null,
  name: null,
  type: null,
});
const loadItems = async (options: TableLoadItemsOptions) => {
  const { status, data } = await apiRequest(
    () =>
      ResourcesService.getResourceListAsync({
        resourceNameSearch: filter.name ?? undefined,
        resourceType: filter.type ?? undefined,
        active: filter.active ?? undefined,
        offset: options.offset,
        limit: options.limit,
        sortBy: options.sortBy ?? undefined,
        isSortAscending: options.isSortAscending ?? undefined,
      }),
    apiKeys.resourceList(),
  );

  return {
    ok: status?.value === ResponseStatus.success,
    items: data.value?.items ?? ([] as ResourceDto[]),
    total: data.value?.totalItems ?? 0,
  };
};

const newResourceId = 'newResource';
const dialogRefs = ref<Record<string, InstanceType<typeof Dialog>>>({});
const getDialogRef: (id: string) => VNodeRef = (id) => (ref) => {
  dialogRefs.value[id] = ref as InstanceType<typeof Dialog>;
};
const openDialog = (id: string) => {
  dialogRefs.value[id]?.openDialog();
};

const resourcesTable = ref();
const tableReload = () => resourcesTable.value.reload();
const debouncedTableReload = debounce(tableReload, 500);

watch(
  () => filter.active,
  (val, prevVal) => {
    if (!isEqual(val, prevVal)) {
      tableReload();
    }
  },
);

watch(
  () => filter.name,
  (val, prevVal) => {
    if (!isEqual(val, prevVal)) {
      debouncedTableReload();
    }
  },
);

watch(
  () => filter.type,
  (val, prevVal) => {
    if (!isEqual(val, prevVal)) {
      tableReload();
    }
  },
);

const deleteItem = async (item: ResourceDto) => {
  const { error } = await apiRequest(() =>
    ResourcesService.deleteResourceAsync({
      id: item.id,
    }),
  );

  if (error.value) {
    snackbar.add({
      type: SnackbarType.error,
      text: t('resources.deleteResourceError'),
    });
    return;
  }

  snackbar.add({
    type: SnackbarType.success,
    text: t('resources.deleteResourceSuccess'),
  });

  tableReload();
};
</script>
